<template>
  <div>
   

    
    <div class="row">
      <div class="col-xl-12">
        <div class="panel">
          <div class="panel-heading bg-dark text-light">
            <h3 class="panel-title bold">Data Preventive Calendar</h3>
          </div>
          <div class="panel-body p-10">
            <div slot="table-actions p-10">
                <div style="display: flex;float :right;padding-bottom: 10px;">
                  <button type="button" v-on:click="fetchData()" class="btn btn-default mr-2">
                    <i class="fa fa-sync" :class="{ 'fa-spin': this.isLoading }"></i>
                  </button>
                  <!-- <button @click="showModal2" class="btn btn-warning ml-1">
                  Ganti Tanggal
                </button> -->

                  <router-link v-if="userLevel == 'Administrator' || userLevel == 'SPV'" to="/schedule/preventive/create"
                    class="btn btn-primary mr-2">
                    <i class="fa fa-plus d-md-none"></i>
                    <span class="d-none d-md-block">Jadwal Baru</span>
                  </router-link>
                </div>
                </div>
             <div>

            <full-calendar :events="events" :config="config" />
            <!-- <vue-event-calendar :events="demoEvents"></vue-event-calendar> -->

            </div>
          </div>
        </div>
      </div>
    </div>
    
    <!-- <div class="row">
      <div class="col-xl-12">
        <div class="panel">
          <div class="panel-heading bg-dark text-light">
            <h3 class="panel-title bold">Data Preventive List</h3>
          </div>
          <div class="panel-body p-0">
            <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" @on-search="onSearch"
              :totalRows="totalRecords" :isLoading.sync="isLoading" :search-options="{
                enabled: true,
                trigger: 'keyup',
                placeholder: 'Pencarian',
              }" :pagination-options="{
                enabled: true,
                perPageDropdownEnabled: true,
                perPageDropdown: [10, 20, 30, 40, 50, 100],
                dropdownAllowAll: false,
                position: 'bottom',
                rowsPerPageLabel: '',
                nextLabel: '',
                prevLabel: '',
                ofLabel: 'of',
                pageLabel: 'page',
                allLabel: '',
              }" :rows="rows" :columns="columns">
              <div slot="emptystate" class="text-center">
                {{ tableInfo }}
              </div>
              <div slot="table-actions">
                <div style="display: flex">
                  <button type="button" v-on:click="fetchData()" class="btn btn-default mr-2">
                    <i class="fa fa-sync" :class="{ 'fa-spin': this.isLoading }"></i>
                  </button>
                  <router-link v-if="this.userLevel == 'Administrator'" to="/schedule/preventive/create"
                    class="btn btn-primary mr-2">
                    <i class="fa fa-plus d-md-none"></i>
                    <span class="d-none d-md-block">Jadwal Baru</span>
                  </router-link>
                </div>
              </div>
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'maintain'">
                  {{ props.row.type_ticket }} Maintenance
                </span>

                <span v-if="props.column.field == 'action'">
                  <div>
                    <button class="btn btn-icon btn-success m-r-5" v-on:click="
                      getDetail(props.row.type_ticket, props.row.id_ticket)
                      ">
                      <i class="fa fa-search"></i>
                    </button>
                    <router-link :to="'/schedule/preventive/process/' + props.row.id_ticket
                      " class="btn btn-icon btn-info m-r-5">
                      <i class="fa fa-list"></i>
                    </router-link>
                  </div>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div> -->

    <!-- modal -->
    <b-modal v-model="modalVisible" @hide="beforeModalClose">
      <template #modal-header>
        <h5 class="modal-title">Detail Ticket</h5>
      </template>
      <div>
        <table class="table table-borderless">
          <tbody>
            <tr>
              <td style="width: 110px">No Ticket</td>
              <td style="width: 5px">:</td>
              <td>{{ detail.no_ticket }}</td>
            </tr>
            <tr>
              <td>Subject Ticket</td>
              <td style="width: 5px">:</td>
              <td>{{ detail.subject_ticket }}</td>
            </tr>
            <tr>
              <td>Fill Ticket</td>
              <td style="width: 5px">:</td>
              <td>{{ detail.fill_ticket }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <template #modal-footer>
        <button @click="closeModal" class="btn btn-block btn-info" variant="primary">
          Close
        </button>
      </template>
    </b-modal>

    <b-modal v-model="modalVisible2" @hide="beforeModalClose2">
      <template #modal-header>
        <h5 class="modal-title">Ganti Tanggal</h5>
      </template>
      <div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="bold" for="change_date"
                >Date :
              </label>
              <input
                v-model="changeDate"
                type="date"
                class="form-control"
                placeholder="Input Tanggal"
                
              />
            </div>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <button @click="closeModal2" class="btn btn-info" variant="primary">
          Tutup
        </button>
        <button
          class="btn btn-success"
          @click="postCatergory"
          variant="primary"
          :disabled="loadingCategory"
        >
          <i v-if="loadingCategory" class="fas fa-circle-notch fa-spin"></i>
          Submit
        </button>
      </template>
    </b-modal>


  </div>
</template>

<script>
import axios from "axios";
import userData from "../../plugins/userData";
import deleteData from "@/plugins/deleteData";
import paginations from "@/plugins/paginations";
import formatting from "@/plugins/formatting";
import Swal from "sweetalert2";



export default {
  mixins: [userData, deleteData, paginations, formatting],
  data() {
    return {
     
      config: {
        displayEventTime: false,
        header: {
   
    right: 'month'
  },
        defaultView: 'month',
         eventClick: (event) => {

       
         
            if((this.userData.level == 'Administrator' || this.userData.level == 'SPV') && event.color == 'grey' ){
              Swal.fire({
              title: "Do you want to edit?",
              showDenyButton: true,
              showCancelButton: true,
              confirmButtonText: "Yes, Edit !",
              denyButtonText: `No, Action !`
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                // Swal.fire("Saved!", "", "success");


                // console.log(event, this.userData.level, event.start._i);
                // this.changeDate = event.start._i;
                // this.id_ticket = event.id_ticket;
                // this.showModal2();
                this.$router.push("/schedule/update/"+event.id_ticket).catch(()=>{});


              } else if (result.isDenied) {
                this.$router.push("/schedule/preventive/process/"+event.id_ticket).catch(()=>{});
              }
            });
          }else{
            this.$router.push("/schedule/preventive/process/"+event.id_ticket).catch(()=>{});
          }
          
          // this.selected = event;
        },
      },
      events: [
        // {
        //     title  : 'event1',
        //     start  : '2024-12-01',
        //     color : 'red',
        //     allDay : false,
        // },
        // {
        //     title  : 'event2',
        //     start  : '2024-12-02',
        //     color : 'blue',
        //     end    : '',
        //     allDay : false,
        // },
        // {
        //     title  : 'event3',
        //     start  : '2024-12-04',
        //     allDay : false,
        // },
      ],
      columns: [
        {
          label: "Nomor Ticket",
          field: "no_ticket",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-nowrap",
        },
        {
          label: "Tanggal",
          field: "ticket_created",
          sortable: false,
          formatFn: this.dateFormat,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-center text-wrap",
        },
        {
          label: "Problem",
          field: "cat_ticket",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-wrap",
        },
        {
          label: "Issue",
          field: "subject_ticket",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-wrap",
        },
        {
          label: "Date End",
          field: "ticket_closed",
          sortable: false,
          formatFn: this.dateFormat,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-center text-wrap",
        },
        {
          label: "Status Ticket",
          field: "status_ticket",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-center text-wrap",
        },
        {
          label: "Request By",
          field: "nama_karyawan",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-center text-wrap",
        },
        {
          label: "Aksi",
          field: "action",
          sortable: false,
          width: "100px",
          tdClass:
            "text-center f-s-14 f-w-600 text-inverse valign-middle text-nowrap",
          thClass: "text-center text-nowrap",
        },
      ],
      rows: null,
      tableInfo: "Loading data",
      isLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: "",
            type: "",
          },
        ],
        page: 1,
        perPage: 10,
      },
      filter: {
        district: "",
        village: "",
      },
      detail: {
        no_ticket: "",
        subject_ticket: "",
        fill_ticket: "",
        nama_device: "",
      },
      searchTerm: "",
      timeoutSearch: null,
      loadDPT: false,
      districtList: [],
      villageList: [],
      modalVisible: false,
      modalVisible2: false,
      conditionMet: false,
      conditionMet2: false,
      changeDate :"",
      id_ticket :"",
      loadingCategory : false,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      axios
        .get(this.$url + "/v2/schedule/ticket/", {
          headers: {
            token: this.userToken,
          },
          params: {
            search: this.searchTerm,
            page: this.serverParams.page,
            limit: this.serverParams.perPage,
            district: this.filter.district,
            village: this.filter.village,
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          for (let i = 0; i < response.data.events.length; i++) {
            response.data.events[i].allDay = false;
            
          }
          this.events = response.data.events;
        //    [
        // {
        //     title  : 'event1',
        //     start  : '2024-12-01',
        //     color : 'red',
        //     allDay : false,
        // }];
          console.log('events', this.events)
          this.totalRecords = response.data.total;
          if (this.totalRecords <= 0) {
            this.tableInfo = "Empty data";
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
          this.error = error.message;
        });
    },
    postCatergory() {
      this.loadingCategory = true;
      const formData = new FormData();
      formData.append('changeDate', this.changeDate);
      formData.append('id_ticket', this.id_ticket);
      axios
        .post(`${this.$url}/v2/schedule/change`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          console.log(response)
          this.closeModal2();
          this.fetchData();
        })
        .catch((error) => {
          this.loadingCategory = false;
          console.log(error);
          this.error = error.message;
        });
    },
    getDetail(type, id) {
      this.isLoading = true;
      axios
        .get(this.$url + "/v2/ticket/detail/" + id, {
          headers: {
            token: this.userToken,
          },
          params: {
            type: type,
          },
        })
        .then((response) => {
          console.log(response.data.data);
          for (let key in response.data.data) {
            this.detail[key] = response.data.data[key];
          }
          this.showModal();
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
          this.error = error.message;
        });
    },
    showModal() {
      this.modalVisible = true;
      this.conditionMet = false;
    },
    closeModal() {
      this.modalVisible = false;
      this.conditionMet = true;
    },
    beforeModalClose(evt) {
      if (!this.conditionMet) {
        evt.preventDefault();
      }
    },
    showModal2() {
      this.modalVisible2 = true;
      this.conditionMet2 = false;
    },
    closeModal2() {
      this.modalVisible2 = false;
      this.conditionMet2 = true;
    },
    beforeModalClose2(evt2) {
      if (!this.conditionMet2) {
        evt2.preventDefault();
      }
    },
  },
};
</script>
<style>
/* .fc-resizer{
  display: none;
} */
@import '~fullcalendar/dist/fullcalendar.css';
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>